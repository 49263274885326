import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { PlayerState } from 'src/app/models/video.interface';
import { changeVideoExtension } from '../video-url-transformation-functions';

@Component({
  selector: 'app-video-replay',
  templateUrl: './video-replay.component.html',
  styleUrls: ['./video-replay.component.scss']
})
export class VideoReplayComponent implements OnInit {
  private videoUrl: string;
  @Input() useSmallVideo = false;
  @Input() useWebmVideo = false;
  @Input() usePosterWithTimestamp = false;
  @Input() set video(videoUrl: string) {
    this.videoUrl = videoUrl;
    this.videoMp4Url = changeVideoExtension(videoUrl, 'mp4');

    if (this.useWebmVideo) {
      this.videoWebmUrl = changeVideoExtension(videoUrl, 'webm');
    }

    if (this.useSmallVideo && this.videoMp4Url.includes('upload_')) {
      this.smallVideoMp4Url = this.videoMp4Url.replace('upload_', 'small_upload_');
    }
    this.cdr.detectChanges();
    this.player.load();
  }

  autoplay = false;
  @Input() set useAutoplay(autoplay: boolean) {
    this.autoplay = autoplay;

    if (autoplay) {
      this.playerState = PlayerState.playing;
    }
  }

  @Input() useControls = false;
  @Input() useCustomControls = true;
  @Input() useLoop = false;
  @Input() useProgressTracker = false;
  @Input() useCenteredVideo = true;
  @Input() showBackgroundImage = true;

  @ViewChild('player') playerElement: ElementRef<HTMLVideoElement>;

  videoMp4Url: string;
  smallVideoMp4Url: string;
  videoWebmUrl: string;
  videoPosterUrl: string;
  videoDuration: number;
  playbackProgress = 0;

  playerStateEnum = PlayerState;
  playerState: PlayerState = PlayerState.paused;

  constructor(
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2
  ) { }

  get player(): HTMLVideoElement {
    return this.playerElement.nativeElement;
  }

  ngOnInit(): void {
    this.videoPosterUrl = this.usePosterWithTimestamp ? this.setPosterWithTimestamp(this.videoUrl) : changeVideoExtension(this.videoUrl, 'jpg');
  }

  togglePlayPause(event: Event): void {
    event.stopPropagation();

    if (this.playerState === PlayerState.playing) {
      this.player.pause();
      this.playerState = PlayerState.paused;
    } else if (this.playerState === PlayerState.paused || this.playerState === PlayerState.finished) {
      this.player.play();
      this.playerState = PlayerState.playing;
    }

    this.cdr.detectChanges();
  }

  videoReady(): void {
    this.videoDuration = this.player.duration;
    this.cdr.detectChanges();
  }

  videoEnded(): void {
    this.playerState = PlayerState.finished;
    this.cdr.detectChanges();
  }

  videoTimeUpdated(): void {
    this.playbackProgress = (100 / this.videoDuration) * this.player.currentTime;
    this.cdr.detectChanges();
  }

  setPosterWithTimestamp(videoUrl: string): string {
    const videoPosterUrl = changeVideoExtension(videoUrl, 'jpg');
    const timestamp = new Date().getTime();
    return `${videoPosterUrl}?t=${timestamp}`;
  }

  @HostListener('window:resize')
  setVideoStyles(): void {
    const video = this.player;

    if (!this.useCenteredVideo) {
      this.renderer.setStyle(video, 'max-width', '100%');
      this.renderer.setStyle(video, 'max-height', '100%');
      return;
    }

    const videoAspectRatio = video.clientWidth / video.clientHeight;
    const parentElementAspectRatio = video.parentElement.clientWidth / video.parentElement.clientHeight;

    let width: string;
    let height: string;

    if (videoAspectRatio > parentElementAspectRatio) {
      width = 'auto';
      height = '100%';
    } else {
      width = '100%';
      height = 'auto';
    }

    this.renderer.setStyle(video, 'width', width);
    this.renderer.setStyle(video, 'height', height);

    this.cdr.detectChanges();
  }
}
